import dynamic from 'next/dynamic';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';

const ChevronType1 = dynamic(() =>
  import('./ChevronTypes/ChevronType1').then((mod) => mod.ChevronType1)
);
const ChevronType2 = dynamic(() =>
  import('./ChevronTypes/ChevronType2').then((mod) => mod.ChevronType2)
);
const ChevronType3 = dynamic(() =>
  import('./ChevronTypes/ChevronType3').then((mod) => mod.ChevronType3)
);
const ChevronType4 = dynamic(() =>
  import('./ChevronTypes/ChevronType4').then((mod) => mod.ChevronType4)
);

export function Chevrons(props) {
  const { images } = props;
  const {
    widgetContextState: { globalStyle },
  } = useWidgetDndContextData();

  if (!images?.length) {
    return null;
  }
  const { isDesktop, widgetOptions } = props;

  const showChevrons = images?.length > 1;
  const showArrows =
    images?.length > 1 &&
    (isDesktop || (!isDesktop && globalStyle?.chevrons?.showArrowInMobile));
  const chevronAnimationTiming = widgetOptions?.autoplaySpeed + 'ms';
  const type = globalStyle?.chevrons?.selected;
  const arrowPosition = globalStyle?.chevrons?.arrowPosition;
  const isAutoPlayOn = widgetOptions.autoPlay ?? true;

  const _PushPropsHOC = (Component: any) => {
    const commonProps = {
      showArrows,
      chevronAnimationTiming,
      showChevrons,
      isAutoPlayOn,
      arrowPosition,
      ...props,
    };
    const comp = () => <Component {...commonProps} />;
    return comp;
  };

  function _RenderChevrons() {
    switch (type) {
      case 0:
        return ChevronType1;
      case 1:
        return ChevronType2;
      case 2:
        return ChevronType3;
      case 3:
        return ChevronType4;
      default:
        return ChevronType1;
    }
  }

  const ChevronElement = _PushPropsHOC(_RenderChevrons());
  return (
    <div className="tw-pointer-events-none tw-absolute tw-inset-0 tw-z-10 tw-flex">
      <ChevronElement />
    </div>
  );
}
